import React from "react";
import { graphql } from "gatsby";
import DefaultLayout from "../../components/layouts/DefaultLayout";
import SEO from "../../components/layouts/SEO";
import Hero from "../../components/StaticPage/Hero";
import UseCaseList from "../../components/TrainingPage/UseCaseList";

interface IHomePage {
  data: any;
}

const HomePage: React.FC<IHomePage> = ({ data }) => {
  return (
    <>
      <SEO
        url={data?.site?.siteMetadata?.siteUrl}
        title="Real Time Analytics By Industry | Ensemble"
        desc="Example Use Cases Of Real-Time Analytics"
        ogImage={`${data?.site?.siteMetadata?.siteUrl}/assets/img/home.png`}
      />
      <DefaultLayout>
      <Hero
        title='Real Time Analytics By Industry'
        description='Example Use Cases Of Real-Time Analytics'
        backgroundColor={["#40b7de", "#1976e0"]}
      />
        <UseCaseList type="Industries" />
      </DefaultLayout>
    </>
  );
};

export const query = graphql`
  query lessons {
    site {
      siteMetadata {
        siteUrl
        title
        description
      }
    }
  }
`;

export default HomePage;
